<template>
  <div class="container">
    <a-collapse v-model:activeKey="activeKey" style="border-top-left-radius: 15px;border-top-right-radius: 15px;">
      <a-collapse-panel key="1">
        <template #header>
          <h1>COMPANY</h1>
        </template>
        <div class="controller">
          <div class="item">
            <span>ตัวกรองพาร์ทเนอร์: </span>
            <a-select v-model:value="selectedPartnerID" style="width: 300px">
              <a-select-option :value="null">ทั้งหมด</a-select-option>
              <a-select-option v-for="partner in partner_list" :key="partner.id" :value="partner.id">{{ partner.name
              }}</a-select-option>
            </a-select>
          </div>
          <a-button @click="show_create_company" type="primary">
            สร้างบริษัท
          </a-button>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <div class="wrapper">
      <a-card class="big-card" v-for="{ id, partner, partner_id, name, address, tax_id, max_payable, pay_day, contact_name, contact_phone, contact_email, payroll_day, workstart_day, unpay_day_start, unpay_day_end, remark }, i in
      filteredCompany" :key="id" hoverable :tab-list="[
        { key: 'info', tab: 'ข้อมูลทั่วไป' },
        { key: 'contact', tab: 'ข้อมูลผู้ติดต่อ' },
        { key: 'setting', tab: 'การตั้งค่าวงเงิน' },
      ]" :active-tab-key="menu[id]" @tabChange="(value) => { menu[id] = value }">
        <template #title>
          <div class="title">
            <h3>{{ name }}</h3>
            <div class="partner">
              <span style="opacity: 0.5;">{{ partner ? partner.name : '-' }}</span>
            </div>
          </div>
        </template>

        <div class="detail" v-if="menu[id] == 'info'">
          <p>ที่อยู่: {{ address }}</p>
          <p>เลขประจำตัวผู้เสียภาษี: {{ tax_id }}</p>
        </div>
        <div class="detail" v-else-if="menu[id] == 'contact'">
          <p>ชื่อผู้ติดต่อ: {{ contact_name }}</p>
          <p>เบอร์โทรศัพท์: {{ contact_phone }}</p>
          <p>อีเมล: {{ contact_email }}</p>
        </div>
        <div class="detail" v-else-if="menu[id] == 'setting'">
          <p>วันที่จ่ายค่าคอมมิชชั่น: {{ payroll_day }}</p>
          <p>วันที่จ่ายค่าคอมมิชชั่น: {{ workstart_day }}</p>
          <p>ช่วงวันที่ห้ามเบิก: {{ unpay_day_start }} - {{ unpay_day_end }}</p>
          <p>หมายเหตุ: {{ remark }}</p>
        </div>
        <template #actions>
          <a-tooltip placement="topLeft"
            :title="partnerConditions(filteredCompany[i]) ? `เงื่อนไขบริษัทไม่สมบูรณ์` : 'อนุญาติการเบิกเงินพนักงาน'">
            <a-switch @change="toggle_allowance(id, $event)" v-model:checked="filteredCompany[i].is_active"
              :disabled="partnerConditions(filteredCompany[i])" />
          </a-tooltip>
          <a-tooltip placement="topLeft" title="นำเข้าพนักงาน">
            <span @click="show_import_modal(id)" class="material-symbols-outlined">publish</span>
          </a-tooltip>
          <a-tooltip placement="topLeft" title="แก้ไข">
            <span @click="show_create_company(filteredCompany[i])" class="material-symbols-outlined">edit</span>
          </a-tooltip>
          <a-tooltip placement="topLeft" title="ลบ">
            <span @click="delete_company(id)" class="material-symbols-outlined">delete</span>
          </a-tooltip>
        </template>
      </a-card>
    </div>

    <!-- Create Company Drawer -->
    <a-drawer :closable="true" :visible="create_company_modal" :destroyOnClose="true" :maskClosable="true" :width="600"
      title="สร้างบริษัท" @close="create_company_modal = false">
      <a-form :model="create_company_form" layout="vertical" @finish="submit_create_company">
        <a-divider orientation="left" plain>
          <h3># ข้อมูลทั่วไป</h3>
        </a-divider>

        <a-form-item name="partner_id" label="กลุ่ม Partner">
          <a-select v-model:value="create_company_form.partner_id">
            <a-select-option :value="0">ไม่มี</a-select-option>
            <a-select-option v-for="partner in partner_list" :key="partner.id" :value="partner.id">{{ partner.name
            }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item name="name" label="ชื่อบริษัท">
          <a-input v-model:value="create_company_form.name" />
        </a-form-item>

        <a-form-item name="address" label="ที่อยู่">
          <a-input v-model:value="create_company_form.address" />
        </a-form-item>

        <a-form-item name="tax_id" label="เลขประจำตัวผู้เสียภาษี">
          <a-input v-model:value="create_company_form.tax_id" />
        </a-form-item>

        <a-divider orientation="left" plain>
          <h3># ข้อมูลผู้ติดต่อ</h3>
        </a-divider>

        <a-row justify="space-between">
          <a-col :span="11">
            <a-form-item name="contact_name" label="ชื่อผู้ติดต่อ">
              <a-input v-model:value="create_company_form.contact_name" />
            </a-form-item>
          </a-col>

          <a-col :span="11">
            <a-form-item name="contact_phone" label="เบอร์โทรศัพท์">
              <a-input v-model:value="create_company_form.contact_phone" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-form-item name="contact_email" label="อีเมล">
          <a-input v-model:value="create_company_form.contact_email" />
        </a-form-item>
        <a-divider orientation="left" plain>
          <h3># เอกสาร​ & รายละเอียดสัญญา</h3>
        </a-divider>

        <a-row justify="space-between">
          <a-col :span="11">
            <a-form-item name="sum_employee" label="วันที่เริ่มและสิ้นสุดสัญญา">
              <a-input type="text" v-model:value="create_company_form.contract_dt" />
            </a-form-item>
          </a-col>
          <a-col :span="11">
            <a-form-item name="pay_transfer_dt" label="ระยะเวลาสัญญา">
              <a-input type="text" v-model:value="create_company_form.contract_month" />
            </a-form-item>
          </a-col>
          <a-col :span="24" align="end">
            <a-upload v-model:file-list="companyFiles" action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
              @change="upload_files($event, 'file')" method="GET" @remove="remove_uploaded_file($event)">
              <a-button v-if="!create_company_form.file">
                อัพโหลดไฟล์
              </a-button>
            </a-upload>
          </a-col>
        </a-row>

        <a-divider orientation="left" plain>
          <h3># การตั้งค่าวงเงิน</h3>
        </a-divider>

        <a-row justify="space-between">
          <a-col :span="11">
            <a-form-item name="company_director" label="รายชื่อผู้บริหาร">
              <a-input type="text" v-model:value="create_company_form.company_director" />
            </a-form-item>
          </a-col>
          <a-col :span="11">
            <a-form-item name="sum_employee" label="จำนวนพนักงาน">
              <a-input type="number" v-model:value="create_company_form.sum_employee" />
            </a-form-item>
          </a-col>
          <a-col :span="11">
            <a-form-item name="pay_transfer_dt" label="วันเริ่มต้นนับรอบเงินเดือน">
              <a-input type="text" v-model:value="create_company_form.pay_transfer_dt" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row justify="space-between">
          <a-col :span="11">
            <a-form-item name="max_payable" label="วงเงินที่เบิกได้ (%)">
              <a-input type="number" v-model:value="create_company_form.max_payable" />
            </a-form-item>
          </a-col>
          <a-col :span="11">
            <a-form-item name="workstart_day" label="วันเริ่มต้นนับรอบเงินเดือน">
              <a-input type="number" :min="1" :max="31" v-model:value="create_company_form.workstart_day" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row justify="space-between">
          <a-col :span="11">
            <a-form-item name="payroll_day" label="วันที่คิดค่าคอมมิชชั่น">
              <a-input type="number" :min="1" :max="31" v-model:value="create_company_form.payroll_day" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="11">
            <a-form-item name="pay_day" label="วันที่จ่ายเงินเดือน">
              <a-input v-model:value="create_company_form.pay_day" disabled />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row justify="space-between">
          <a-col :span="11">
            <a-form-item name="unpay_day_start" label="วันที่ห้ามเบิก (เริ่มต้น)">
              <a-input type="number" :min="1" :max="31" v-model:value="create_company_form.unpay_day_start" />
            </a-form-item>
          </a-col>
          <a-col :span="11">
            <a-form-item name="unpay_day_end" label="วันที่ห้ามเบิก (สิ้นสุด)">
              <a-input type="number" :min="1" :max="31" v-model:value="create_company_form.unpay_day_end" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-form-item name="remark" label="รอบการคิดเงินเดือน">
          <a-textarea :rows="5" v-model:value="create_company_form.salary_round" />
        </a-form-item>

        <a-form-item name="remark" label="หมายเหตุ">
          <a-textarea :rows="5" v-model:value="create_company_form.remark" />
        </a-form-item>

        <a-divider orientation="left" plain>
          <h3># ข้อมูลเชื่อมต่อ 3rd Party</h3>
        </a-divider>

        <a-row justify="space-between">
          <a-col :span="11">
            <a-form-item name="kot_client_id" label="King Of Time ClientID">
              <a-input type="text" v-model:value="create_company_form.kot_client_id" />
            </a-form-item>
          </a-col>
          <a-col :span="11">
            <a-form-item name="kot_client_secret" label="King Of Time Client Secret">
              <a-input type="text" v-model:value="create_company_form.kot_client_secret" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-form-item>
          <a-button type="primary" html-type="submit">บันทึก</a-button>
        </a-form-item>
      </a-form>
    </a-drawer>

    <!-- Import Company Modal -->
    <a-modal :visible="import_modal" :destroyOnClose="true" :footer="null" :maskClosable="!import_steps" :width="1000"
      :title="`นำเข้าข้อมูลพนักงาน: ${importCompany?.name}`" @cancel="closeImport">
      <a-steps :current="import_steps" size="small">
        <a-step v-for="item, i in ['นำเข้ารายชื่อ', 'ตรวจสอบรายการนำเข้า', 'Confirm']" :key="i" :title="item" />
      </a-steps>
      <div class="steps-content">
        <div class="steps-content-inner" v-if="import_steps == 0">
          <a-upload-dragger v-model:fileList="fileList" name="file" :show-upload-list="false"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76" method="GET" @change="handleChange"
            @drop="handleDrop">
            <p class="ant-upload-drag-icon">
              <span class="material-symbols-outlined" style="font-size: 76px">upload_file</span>
            </p>
            <p class="ant-upload-text">คลิก หรือ ลากไฟล์มาที่นี่</p>
            <p class="ant-upload-hint">
              รองรับไฟล์สกุล .xlsx, .xls สามารถอัพโหลดเอกสารได้ครั้งละ 1 ไฟล์
            </p>
          </a-upload-dragger>
          <a-divider v-if="importCompany.kot_client_id" orientation="left">3rd Party Connect</a-divider>
          <KingOfTime v-if="importCompany.kot_client_id" :company_id="importCompany.id"
            :company_client_id="importCompany.kot_client_id" :company_client_secret="importCompany.kot_client_secret"
            :company_kot_token="importCompany.kot_token" @kot_proceed="kotImport" />

        </div>
        <div v-else-if="import_steps == 1">
          <a-table :columns="[
            { title: 'ชื่อ นามสกุล', dataIndex: 'fullname' },
            { title: 'เงินเดือน', dataIndex: 'salary' },
            { title: 'เบอร์โทรศัพท์', dataIndex: 'phone' },
            { title: 'ธนาคาร', dataIndex: 'bank_name' },
            { title: 'เลขบัญชี', dataIndex: 'bank_no' },
          ]" :data-source="import_result" :pagination="false" :scroll="{ y: 240 }">

            <template #bodyCell="{ column, record, index }">
              <template v-if="column.dataIndex === 'salary'">
                <a-input-number v-model:value="record[column.dataIndex]" :min="0" :max="100000" style="width: 100%;" />
              </template>
              <template v-else-if="column.dataIndex === 'phone'">
                <a-input v-model:value="record[column.dataIndex]" style="width: 100%;" />
              </template>
              <template v-else-if="column.dataIndex === 'bank_name'">
                <a-input v-model:value="record[column.dataIndex]" style="width: 100%;" />
              </template>
              <template v-else-if="column.dataIndex === 'bank_no'">
                <a-input v-model:value="record[column.dataIndex]" style="width: 100%;" />
              </template>

            </template>

            <template #footer>
              <a-row justify="end">
                <a-col>
                  <a-tag color="success">พบรายการนำเข้า {{ import_result.length }} รายการ</a-tag>
                  <a-button type="primary" @click="save_import_result()">นำเข้าข้อมูล</a-button>
                </a-col>
              </a-row>
            </template>
          </a-table>
        </div>
        <div v-else-if="import_steps == 2">
          <div style="margin: 20px 0;text-align: center;">
            <a-spin />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import AdminService from '../api/AdminService';
import Swal from 'sweetalert2';
import { xlxsBase64toJson } from '@/components/helper.js'
import KingOfTime from '@/components/KingOfTime.vue'

export default {
  components: { KingOfTime },
  name: 'Company',
  data() {
    return {
      company_list: [],
      partner_list: [],
      companyFiles: [],
      create_company_modal: false,
      menu: {},
      import_modal: false,

      import_steps: 0,
      activeKey: '1',
      selectedPartnerID: null,
      create_company_form: {
        name: '',
        address: '',
        tax_id: '',
        max_payable: '',
        pay_day: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        contract_dt: '',
        contract_month: '',
        payroll_day: '',
        workstart_day: '',
        remark: '',
        file: '',
      },
      import_result: [],
      fileList: [],
      importCompany: null,
      isKotImport: false
    }
  },
  computed: {
    filteredCompany() {
      return this.company_list.filter(({ partner_id }) => {
        if (this.selectedPartnerID === null) return true
        return partner_id === this.selectedPartnerID
      })
    }
  },
  methods: {
    async remove_uploaded_file({ uid }) {
      this.create_company_form[uid] = ''
    },
    upload_files(event, field) {
      const vue = this
      const file = event.file.originFileObj
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
          vue.create_company_form[field] = reader.result
      }

      return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
    },
    partnerConditions(company) {
      const partner_id = company.partner_id
      //HumanSoft Condition
      if (partner_id == 6) {
        return (company.contract_dt == null || company.contract_dt == '') || (company.contract_month == null || company.contract_month == '')

      } else {
        return false
      }
    },
    kotImport(data) {
      this.import_result = data
      this.import_steps = 1
      this.isKotImport = true
    },
    closeImport() {
      this.isKotImport = false
      this.import_modal = false
      this.import_steps = 0
      this.fileList = []
      this.import_result = []
    },
    toggle_allowance(id, value) {
      this.$message.loading({ content: 'Loading...', key: 'allowance' });
      AdminService.update_model('Company', id, {
        data: {
          is_active: value
        }
      })
        .then(() => {
          this.$message.success({ content: 'Update Success!', key: 'allowance', duration: 1.5 });
          this.get_company()
        })
        .catch(() => {
          this.$message.error({ content: 'Update Failed!', key: 'allowance', duration: 1.5 });
        })
    },
    convert_list(array) {
      return array.map((name) => {
        return {
          fullname: name["ชื่อ นามสกุล"],
          salary: name["เงินเดือน"],
          phone: name["เบอร์โทรศัพท์"].replace(/-/g, ''),
          bank_name: name["บัญชีธนาคารที่รับเงินเดือน"],
          bank_no: name["__EMPTY"]
        }
      })
    },
    save_import_result() {
      this.$message.loading({ content: 'กำลังนำเข้าข้อมูล...', key: 'import' });
      this.import_steps = 2
      const body = this.import_result.map((item) => {
        const record = {
          fullname: item.fullname,
          salary: item.salary,
          phone: item.phone,
          bank_name: item.bank_name,
          bank_no: item.bank_no,
          company_id: this.importCompany.id,
          is_imported: true
        }
        if (this.isKotImport) {
          record.kot = true
        }

        return record
      })

      AdminService.import_users({ data: body })
        .then(res => {
          if (res) {
            this.$message.success({ content: `นำเข้าข้อมูลสำเร็จ ${res?.entries}`, key: 'import', duration: 1.5 });
            this.import_modal = false
            this.get_company()
          } else {
            this.$message.error({ content: 'นำเข้าข้อมูลไม่สำเร็จ', key: 'import', duration: 1.5 });
          }
        })

    },
    import_change(event) {
      this.$message.loading({ content: 'กำลังตรวจสอบไฟล์...', key: 'import' });
      const vue = this
      const file = event.file.originFileObj
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const result = xlxsBase64toJson(reader.result.split(',')[1])
        this.import_steps = 1
        this.import_result = vue.convert_list(result)
        this.$message.success({ content: 'ตรวจสอบไฟล์สำเร็จ', key: 'import', duration: 1.5 });
      }
    },
    handleChange(file) {
      this.import_change(file)
    },
    handleDrop(file) {
      this.import_change(file)
    },
    async get_company() {
      const res = await AdminService.get_model('Company', { id: 'all', page: 1, perpage: 500, load: ['partner'] });
      this.company_list = res.data
      this.company_list.forEach((item) => {
        this.menu[item.id] = 'info'
        if (item.is_active) {
          item.is_active = true
        } else {
          item.is_active = false
        }
      })
    },
    clear_form() {
      this.create_company_form = {
        name: '',
        address: '',
        tax_id: '',
        max_payable: '',
        pay_day: '',
        contact_name: '',
        contact_phone: '',
        contract_dt: '',
        contract_month: '',
        contact_email: '',
        payroll_day: '',
        workstart_day: '',
        remark: '',
        file: '',
      }
    },
    show_import_modal(id) {
      this.import_modal = true
      this.importCompany = this.company_list.find((item) => item.id == id)
    },
    show_create_company(selected) {
      this.create_company_modal = true
      if (selected) {
        this.create_company_form = selected
        this.companyFiles = [{
          uid: 'file',
          name: selected.file,
          status: 'done',
          url: selected.file
        }]
      }
    },
    submit_create_company() {
      const { id } = this.create_company_form
      this.$message.loading({ content: 'Loading...', key: 'create_company' });
      const {
        partner_id,
        name,
        address,
        tax_id,
        max_payable,
        pay_day,
        contact_name,
        contact_phone,
        contact_email,
        contract_dt,
        contract_month,
        payroll_day,
        workstart_day,
        unpay_day_start,
        unpay_day_end,
        remark,
        file
      } = this.create_company_form
      const body = {
        partner_id,
        name,
        address,
        tax_id,
        max_payable,
        pay_day,
        contact_name,
        contact_phone,
        contact_email,
        contract_dt,
        contract_month,
        payroll_day,
        workstart_day,
        unpay_day_start,
        unpay_day_end,
        remark,
        file
      }
      if (id) {
        AdminService.update_model('Company', id, { data: body })
          .then(() => {
            this.$message.success({ content: 'Update Success!', key: 'create_company', duration: 1.5 });
            this.create_company_modal = false
            this.clear_form()
            this.get_company()
          })
          .catch(() => {
            this.$message.error({ content: 'Update Failed!', key: 'create_company', duration: 1.5 });
          })
      } else {
        AdminService.create_model('Company', { data: [body] })
          .then(() => {
            this.$message.success({ content: 'Create Success!', key: 'create_company', duration: 1.5 });
            this.create_company_modal = false
            this.clear_form()
            this.get_company()
          })
          .catch(() => {
            this.$message.error({ content: 'Create Failed!', key: 'create_company', duration: 1.5 });
          })
      }
    },
    delete_company(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1890ff',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$message.loading({ content: 'Loading...', key: 'delete_company' });
          AdminService.delete_model('Company', id)
            .then(() => {
              this.$message.success({ content: 'Delete Success!', key: 'delete_company', duration: 1.5 });
              this.get_company()
            })
            .catch(() => {
              this.$message.error({ content: 'Delete Failed!', key: 'delete_company', duration: 1.5 });
            })
        }
      })
    },
    init_menus() {
      for (var key in this.company_list) {
        const { id } = key
        this.menu[id] = 'info'
      }
    },
    get_partner() {
      AdminService.get_model('Partner', { id: 'all', page: 1, perpage: 500 })
        .then((res) => {
          this.partner_list = res.data
        })
    }
  },
  mounted() {
    this.get_partner()
    this.get_company()
  }
}
</script>
