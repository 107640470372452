<template>
    <div>
        <div style="display: flex;justify-content: space-evenly;align-items: center;">
            <div @click="toKotLink" class="button">
                <img style="width: 100%;border-radius: 8px;" :src="logo" />
            </div>

            <a-button v-if="company_client_id && company_client_secret" @click="kot_proceed" >
                นำเข้าข้อมูลจาก KingOfTime
            </a-button>
        </div>
    </div>
</template>

<script>
import kot_logo from '@/assets/kot_logo.png';
import KotService from '@/api/KotService';

export default {
    name: 'KingOfTime',
    props: {
        company_id: Number,
        company_client_id: String,
        company_client_secret: String,
        company_kot_token: String
    },
    data () {
        return {
            logo: kot_logo
        }
    },
    methods: {
        async kot_proceed() {
            this.$message.loading({ content: 'Loading...', key: 'login' });
            const users = await KotService.import_users(this.company_id)
            this.$message.destroy('login');

            this.$emit('kot_proceed', users)
        },
        toKotLink() {
            const kotState = `${this.company_id}`
            
            window.open(`https://api.kingtime.jp/connect/kot/authorize?client_id=${this.company_client_id}&redirect_uri=https://api.employyim.com/callback&state=${kotState}&response_type=code&response_mode=form_post`, '_blank');
        }
    }
}
</script>

<style scoped>
.button {
    width: 290px;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-radius: 8px;
    cursor: pointer;
}
</style>